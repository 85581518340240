.App {
 text-align: center;
}

.App-logo {
 height: 40vmin;
 pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
 .App-logo {
  animation: App-logo-spin infinite 20s linear;
 }
}

.App-header {
 min-height: 100vh;
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: center;
 font-size: calc(10px + 2vmin);
 color: white;
 font-family: "Montserrat";
 padding-left: 25px;
 padding-right: 25px;
 position: relative;
 top: -100px;
}

.App-link {
 color: #61dafb;
}

@keyframes App-logo-spin {
 from {
  transform: rotate(0deg);
 }
 to {
  transform: rotate(360deg);
 }
}
